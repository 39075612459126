import React from 'react'
import PropTypes from 'prop-types'

const Blurbs = ({ blurbs }) => (
    <div>
        {blurbs.map((blurb, id) => (
            <div>
                {blurb.text}
                <br /> <br />
            </div>
            
    ))}
    </div>
)
Blurbs.propTypes = {
    blurbs: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string
        })
    ),
}

export default Blurbs
