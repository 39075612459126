import React from 'react'
import PropTypes from 'prop-types'

const News = ({ news }) => (
  <div>
    {news.map((newsItem, id) => (
      <article className='message is-danger' key={id}>
        <div className='message-body'>
          {newsItem.description}
        </div>
      </article>
    ))}
  </div>
)
News.propTypes = {
  news: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
    })
  ),
}

export default News
