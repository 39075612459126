import React from "react";
import Helmet from "react-helmet";
import HotBuys from "../HotBuys";
import News from "../News";
import PropTypes from "prop-types";
import Logo from "../Logo/index.js";
import Blurbs from "../../components/Blurbs";
import config from "../../../config";

const HomePageTemplate = ({
  blurbs,
  heading,
  meta_title,
  meta_description,
  news,
  hotBuys
}) => (
  <div>
    <Helmet>
      <title>{meta_title}</title>
      <meta name="description" content={meta_description} />
    </Helmet>
    <section className="hero is-small">
      <div className="hero-body">
        <div className="container">
          <div className="columns is-multiline">
            <div className="column is-12 has-text-centered">
              <Logo />
            </div>

            <div className="column box has-background-black">
              <div className="columns">
                <div className="column has-text-centered">
                  <a
                    className="has-text-primary"
                    href={`tel:` + config.phoneNumber}
                  >
                    {config.phoneNumber}
                  </a>
                </div>
                <div className="column has-text-centered">
                  <a
                    className="has-text-primary"
                    href={`http://maps.google.com/?q=` + config.physicalAddress}
                  >
                    {config.physicalAddress}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* <section className='section'> */}
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              <div>
                <h1 className="has-text-weight-semibold is-size-1">
                  {heading}
                </h1>
                <Blurbs blurbs={blurbs} />
              </div>
              <figure className="image is-4by3">
                <img alt="landscape products" src="/img/loading-a-truck.jpg" />
              </figure>
              {/* <h3 className="has-text-weight-semibold is-size-3">Hot Buys</h3> */}
              {/* <HotBuys hotBuys={hotBuys} /> */}
              {/* <h3 className="has-text-weight-semibold is-size-3">News</h3> */}
              
              <hr />
              <News news={news} />
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* </section> */}
  </div>
);

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  meta_title: PropTypes.string,
  meta_description: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  offerings: PropTypes.shape({
    blurbs: PropTypes.array
  }),
  testimonials: PropTypes.array
};

export default HomePageTemplate;
